













import { defineComponent, ref, onMounted, type PropType } from '@vue/composition-api';
import { type SwiperOptions } from 'swiper/types';

export default defineComponent({
  props: {
    effect: {
      type: String as PropType<'slide' | 'fade'>,
      default: 'slide',
    },
    withBullets: {
      type: Boolean,
      default: true,
    },
    withNavigations: {
      type: Boolean,
      default: true,
    },
    spaceBetween: {
      type: Number,
      default: 0,
    },
    slidesPerView: {
      type: Number,
      default: 1,
    },
    slidesPerViewSp: {
      type: Number,
      default: 1,
    },
    slidesPerGroup: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const containerEl = ref<HTMLDivElement>();
    const wrapperEl = ref<HTMLDivElement>();

    onMounted(async () => {
      const divNodeList = wrapperEl.value.querySelectorAll(':scope > div, :scope > article');
      Array.from(divNodeList, (divEl) => {
        divEl.classList.add('swiper-slide');
      });

      if (divNodeList.length > 1) {
        const options: SwiperOptions = {
          loop: true,
          speed: 600,
          autoplay: {
            delay: 6000,
            disableOnInteraction: false,
          },
          centeredSlides: true,
          spaceBetween: props.spaceBetween,
          slidesPerView: props.slidesPerViewSp,
          slidesPerGroup: props.slidesPerGroup,
          breakpoints: {
            640: {
              slidesPerView: props.slidesPerView,
            },
          },
        };

        if (props.effect === 'fade') {
          options.effect = 'fade';
          options.speed = 1300;
          options.fadeEffect = {
            crossFade: true,
          };
        }

        if (props.withBullets) {
          options.pagination = {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          };
        }

        if (props.withNavigations) {
          options.navigation = {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          };
        }

        const { Swiper } = await import('swiper');
        const { Navigation, Pagination, Autoplay, EffectFade } = await import('swiper/modules');
        new Swiper(containerEl.value, { modules: [Navigation, Pagination, Autoplay, EffectFade], ...options });
      }
    });

    return {
      containerEl,
      wrapperEl,
    };
  },
});
