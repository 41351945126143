






import { defineComponent, computed } from '@vue/composition-api';
import { useUserStore } from '@/js/lib/store/user';
import { PERMISSION_ID_POLICY_UPDATE_202403 } from '@/js/data/permission';
import IModal from '@/js/components/molecules/IModal.vue';
import Policy202403 from './update-policy-202403.vue';

const permissionToComponent = {
  [PERMISSION_ID_POLICY_UPDATE_202403]: Policy202403,
};

export default defineComponent({
  components: {
    IModal,
  },
  props: {
    permissionId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const userStore = useUserStore();
    const { isAgentLogin, isAuthenticated } = userStore;
    const activeComponent = computed(() => {
      if (isAgentLogin) {
        return false;
      }

      if (!userStore.hasPermission(props.permissionId)) {
        return permissionToComponent[props.permissionId];
      }

      return false;
    });
    const shouldNotShowDialog = computed(() => [/^\/news/, /^\/notes/, /^\/privacy/, /^\/rule/].some((pattern) => pattern.test(window.location.pathname)));
    const shown = computed(() => isAuthenticated && Boolean(activeComponent.value) && !shouldNotShowDialog.value);

    return {
      activeComponent,
      shown,
    };
  },
});
